import React from "react";
import ResizeObserver from "rc-resize-observer";

const Layout = ({ children }) => {
	const handleBodySizeChange = ({ height }) => {
		const message = JSON.stringify({
			height: height + 10,
			url: window.location.href,
		});
		window.parent.postMessage(
			message,
			// "https://www.audi.es/",
			"*",
		);
		console.log("postMessage sent", message);
	};

	return (
		<ResizeObserver onResize={handleBodySizeChange}>
			{children}
		</ResizeObserver>
	);
};

export default Layout;
