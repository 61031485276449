import { Link } from "gatsby";
import styled, { css } from "styled-components";
import colors from "./colors";
import { device } from "./size";

export const StyledSection = styled.section`
	position: relative;
	background-color: ${(props) =>
		props.bgColor ? props.bgColor : colors.white};
`;

export const StyledSectionInner = styled.div`
	margin: auto;
	padding: 30px;
	@media ${device.tablet} {
		padding-top: 0;
		padding-bottom: 0;
	}

	@media ${device.laptop} {
		max-width: 1200px;
		padding: 0 80px;
	}
	@media ${device.laptopL} {
		max-width: 100%;
		padding: 0 100px;
	}
	@media ${device.desktopS} {
	}
	${(props) => {
		if (props.noPaddingTopBot) {
			return css`
				padding-top: 0;
				padding-bottom: 0;
			`;
		}
		if (props.noPaddingTop) {
			return css`
				padding-top: 0;
			`;
		}
		if (props.noPaddingBot) {
			return css`
				padding-bottom: 0;
			`;
		}
	}}
`;

export const SliderWrapperCommon = css`
	.slick-slider {
	}
	.slick-list {
		margin-left: -8px;
		margin-right: -8px;
		@media ${device.laptopL} {
			margin-left: -12px;
			margin-right: -12px;
		}
	}

	.slick-track {
	}
	.slick-slide {
		outline: none !important;
		> div {
			box-sizing: border-box;
			padding-left: 8px;
			padding-right: 8px;
			@media ${device.laptopL} {
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}
`;

export const CustomButton = styled.a`
	width: 100%;
	box-sizing: border-box;
	display: inline-block;
	padding: 20px;
	font-size: 14px;
	line-height: 1;
	text-decoration: none;
	text-align: center;
	// margin-right: 10px;

	@media ${device.tablet} {
		max-width: 100%;
		width: auto;
		padding: 20px 100px;
	}
	@media ${device.laptopL} {
		font-size: 20px;
	}

	//transition: background-color 0.3s ease, color 0.3s ease;
	${(props) => {
		if (props.layout === "light") {
			return css`
				border: 1px solid ${colors.white};
				background-color: ${colors.white};
				color: ${colors.black};
				&:hover {
					border: 1px solid ${colors.gray};
					background-color: ${colors.gray};
				}
			`;
		} else if (props.layout === "light2") {
			return css`
				border: 1px solid ${colors.black};
				background-color: ${colors.white};
				color: ${colors.black};
				&:hover {
					border: 1px solid ${colors.grayDark2};
					color: ${colors.grayDark2};
				}
			`;
		} else if (props.layout === "light3") {
			return css`
				border: 1px solid ${colors.white};
				background-color: transparent;
				color: ${colors.white};
				&:hover {
					background-color: ${colors.white};
					color: ${colors.black};
				}
			`;
		} else if (props.layout === "dark") {
			return css`
				border: 1px solid ${colors.black};
				background-color: ${colors.black};
				color: ${colors.white};
				&:hover {
					background-color: ${colors.white};
					color: ${colors.black};
				}
			`;
		}
	}};
`;

export const InfoIcon = styled.span`
	cursor: pointer;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid ${colors.gray};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	color: ${colors.gray};
	font-size: 7px;
	line-height: 1;
`;

export const OfertaConsumo = styled.div`
	margin-top: 0px !important;
	margin-bottom: 10px !important;
	padding: 0 5px;
	font-size: 14px !important;
	font-family: AudiType, sans-serif !important;
	color: ${colors.gray2};
	* {
		font-size: 14px !important;
		font-family: AudiType, sans-serif !important;
	}
	p {
		margin-top: 0 !important;
		margin-bottom: 10px !important;
	}
	strong {
		font-weight: bold !important;
	}
	i {
		font-style: italic !important;
	}
`;

export const OfertaLegal = styled.div`
	margin-top: 0px !important;
	margin-bottom: 10px !important;
	padding: 0 5px;
	font-size: 14px !important;
	font-family: AudiType, sans-serif !important;
	color: ${colors.gray2};
	* {
		font-size: 14px !important;
		font-family: AudiType, sans-serif !important;
	}
	p {
		margin-top: 0 !important;
		margin-bottom: 10px !important;
	}
	strong {
		font-weight: bold !important;
	}
	i {
		font-style: italic !important;
	}
`;

export const LegalesIndex = styled.sup`
	padding-left: 3px;
`;
