import { createGlobalStyle } from "styled-components";
import "../css/fonts.css";

export const GlobalStyle = createGlobalStyle`
	body {
		font-family: "AudiType";
		margin: 0;
	}
	img {
		max-width: 100%;
		height: auto;
	}
`;
